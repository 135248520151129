<template>
  <header class="header">
    <div class="header__temp-mobile">
      <MenuIcon class="header__mobile-menu-icon" @click="$emit('menuClick')" />
      <router-link class="header__logo" to="/home">
        <PleezLogo class="header__logo" />
      </router-link>
    </div>
    <div class="header__actions">
      <!--target= _blank is a big nono.. but for a button with this much
        visibility, i can't bring myself to set it to _self
      -->
      <a v-if="shouldShowMenu" class="header__menu-button" :href="menuUrl" target="_blank">
        <EyeIcon class="header__menu-icon" />
        {{ $t('shared.navbar.seeMenu') }}
      </a>

      <Select
        class="header__button header__restaurants-button"
        displayKey="name"
        :is-clearable="false"
        :defaultValue="selectedRestaurant ?? undefined"
        :isLoading="restaurants.length === 0"
        :values="restaurants"
        :label="$t('shared.navbar.restaurants')"
        @change="handleRestaurantChanged"
      >
        <div class="header__action-icon">
          <HouseIcon />
        </div>

        <template #custom-selected-option="{ value }">
          <div class="header__selected-option-wrapper">
            <p class="header__selected-option-name">
              {{ value.name }}
            </p>

            <p class="header__selected-option-address">
              {{ value.address }}
            </p>
          </div>
        </template>

        <template #custom-label="{ value }">
          <p class="header__restaurant-name">{{ value.name }}</p>
          <div class="header__restaurants-address-wrapper">
            <PinIcon class="header__restaurant-icon" />
            <p class="header__restaurant-address">
              {{ value.address }}
            </p>
          </div>
        </template>
      </Select>

      <Select
        class="header__button header__account-button"
        :values="accountDropdownOptions"
        :label="accountHolder"
        :has-search="false"
        :is-selectable="false"
        :is-clearable="false"
        @change="handleAccountOptionClick"
      >
        <div class="header__action-icon">
          <ProfileIcon />
        </div>

        <template #custom-label="{ value }">
          <div
            class="header__account-option"
            :class="{
              'header__account-option--small': value.label.length >= 25,
            }"
          >
            <component :is="value.icon" class="header__option-icon" />
            <p>{{ $t(value.label) }}</p>
          </div>
        </template>

        <template #custom-footer>
          <span class="header__flags">
            <span class="fi fi-pt" @click="setupLanguage('pt-PT')"></span>
            <span class="fi fi-gb" @click="setupLanguage('en-GB')"></span>
            <span class="fi fi-es" @click="setupLanguage('es-ES')"></span>
          </span>
        </template>
      </Select>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FiltersComponent from '@/components/Filters/FiltersComponent.vue';
import Select from '@/components/Inputs/Select.vue';

import { accountDropdownOptions } from './Header.config';
import { UserRestaurantsResponse, HeaderData, AccountOption } from './Header';
import { Restaurant } from '@/store/v1/store';
import { MenuIcon } from '@pleez-tech/fe-components-library';

import { setupLanguage } from '@/plugins/i18n';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Session } from '@/globals';

export default defineComponent({
  components: {
    Select,
    FiltersComponent,
    MenuIcon,
  },
  data() {
    return {
      menuUrl: '',
      restaurants: [],
      selectedRestaurant: null,
      accountDropdownOptions: accountDropdownOptions,
    } as HeaderData;
  },
  async mounted() {
    this.$store
      .dispatch('getUserRestaurants')
      .then((userData: UserRestaurantsResponse) => {
        this.restaurants = userData.restaurants;
        this.selectedRestaurant = userData.selectedRestaurant;

        // In case no defaut restaurant is selected, we set the first one, and update the store
        // Why do that? For example a restaurant in UK, if we don't have a default restaurant selected
        // the user will see the currency in Euros, which is not correct
        if (!this.selectedRestaurant) {
          this.selectedRestaurant = this.restaurants[0];
          this.$store.commit('setSelectedRestaurant', this.selectedRestaurant);
        }

        this.$store
          .dispatch('getUserRestaurantOrders')
          .catch(() => this.$toast.warning(this.$t('warnings.userOrders')));
      })
      .catch(() => this.$toast.error(this.$t('errors.sessionExpired')));

    this.$store.dispatch('getMenuUrl').then((menu: { url: string }) => {
      if (menu?.url) {
        this.menuUrl = menu.url;
        if (this.menuUrl.includes('glovoapp') && this.menuUrl.includes('api')) {
          //FIXME: this is extremely sketchy
          this.menuUrl = `https://glovoapp.com/pt/pt/lisboa/${this.menuUrl.split('/')[5]}`;
        }
      }
    });
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    shouldShowMenu() {
      return Boolean(this.menuUrl && this.$route.path === '/analytics/sales');
    },
    accountHolder() {
      const token = Session.getSession('pleez-token');
      const tokenPayload = jwtDecode(token!) as JwtPayload & { email: string };
      return tokenPayload.email;
    },
  },
  methods: {
    handleRestaurantChanged(selectedRestaurant: Restaurant[]) {
      if (selectedRestaurant[0].id !== this.selectedRestaurant?.id) {
        this.selectedRestaurant = selectedRestaurant[0];

        let currentRestaurant = this.selectedRestaurant.restaurants_sources.find(
          (restaurant) => restaurant.source.id === this.$store.state.subHeaderSelectedPlatform,
        );

        if (!currentRestaurant) {
          currentRestaurant = this.selectedRestaurant.restaurants_sources[0];
          this.$session.setSession('pleez-selected-platform', currentRestaurant.source.id);
        }

        this.$store.dispatch('renewToken', {
          restaurantId: this.selectedRestaurant.id,
          restaurantSourceId: currentRestaurant.id,
        });
      }
    },
    handleAccountOptionClick(option: AccountOption[]) {
      const value = option[0]?.value;

      switch (value) {
        case 'logout':
          this.handleLogout();
          break;
        case 'settings':
          this.handleSettings();
          break;
        case 'businessSettings':
          this.handleBusinessSettings();
        default:
          break;
      }
    },
    handleLogout() {
      this.$router.push('/logout');
    },
    handleSettings() {
      this.$router.push('/settings/account');
    },
    handleBusinessSettings() {
      this.$router.push('/settings/business');
    },
    setupLanguage,
  },
});
</script>

<style lang="scss" scoped>
.header {
  --selected-option-name-line-height: 19px;
  --selected-option-address-line-height: 15px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
  min-height: var(--header-height);

  padding: var(--spacing-s);

  background-color: var(--brand-grey);

  box-sizing: border-box;

  &__selected-option-name {
    @include add-ellipsis;
    @include brand-font-m;

    line-height: var(--selected-option-name-line-height);
  }

  &__selected-option-address {
    @include add-ellipsis;
    @include brand-font-s;

    font-weight: 300;
    line-height: var(--selected-option-address-line-height);
  }
  &__mobile-menu-icon {
    margin-right: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
  &__menu-button {
    display: none;
    align-items: center;
    justify-content: center;

    width: 140px;
    margin-right: 16px;

    border-radius: 6px;

    color: var(--brand-primary);
    background-color: var(--color-white);
  }
  &__menu-icon {
    margin-right: 8px;

    stroke: var(--brand-tertiary);
  }
  &__restaurants-address-wrapper {
    display: flex;

    margin-top: 4px;

    width: 100%;
  }
  &__restaurant-name {
    @include add-ellipsis;

    width: 100%;
  }
  &__restaurant-address {
    @include add-ellipsis;

    width: 100%;

    font-size: 0.85rem;
    font-weight: 100;
    font-style: italic;
  }
  &__restaurant-icon,
  &__option-icon {
    flex-shrink: 0;
    margin-right: 8px;
    stroke: var(--brand-primary);
  }
  &__modal {
    position: absolute;
  }
  &__flags {
    display: flex;
    margin: 16px 0 8px 24px;

    & > .fi {
      margin-right: 8px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__button {
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;

      width: calc(46px - 4px); //minus left
      height: calc(100% - 8px); //minus top and bottom

      border-radius: 6px;

      opacity: 0.5;

      content: '';
    }
  }
  &__restaurants-button {
    margin: 16px 0 4px 0;

    &:after {
      background-color: #fff6f5;
    }

    .header__action-icon {
      stroke: var(--brand-tertiary);
    }
  }
  &__account-button {
    &:after {
      background-color: #faf5ff;
    }

    .header__action-icon {
      stroke: var(--brand-secondary);
    }

    :deep() .vs__selected-options {
      margin-left: calc(var(--input-height) + 8px);
    }
  }
  &__account-option {
    display: flex;
    align-items: center;

    &--small {
      font-size: 0.9rem;
    }
  }

  &__temp-mobile {
    display: flex;
    align-items: center;
  }

  :deep() {
    .vs__selected-options {
      overflow: hidden;
    }

    .vs__selected,
    .vs__selected > .header__selected-option-wrapper {
      width: 100%;
    }
    .vs__search {
      padding-right: 0;
    }
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;

    padding: 16px 32px;

    &__mobile-menu-icon {
      display: none;
    }
    &__actions {
      flex-direction: row;

      width: fit-content;
    }
    &__restaurants-button {
      margin: 0;
    }
    &__account-button {
      width: 258px;

      margin-left: 16px;
    }
    &__restaurants-button {
      width: 256px;
    }
    &__menu-button {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
@include breakpoint-from('smallDesktop') {
  .header {
    &__restaurants-button {
      .vs__dropdown-menu {
        width: 280px;
        margin-top: var(--spacing-3xs);
        border-radius: var(--vs-border-radius);
      }
      .vs--open .vs__dropdown-toggle {
        border-radius: var(--vs-border-radius);
      }
      .vs__selected {
        margin: 4px 4px 0;
        border: none;
      }
    }
  }
}
</style>
